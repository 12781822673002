.pending-container{
  background-color: rgb(255, 206, 114);
  border-radius: 10px;
  text-decoration: none;
  padding: 1%;
  height: 80%;
  color: rgb(0, 0, 0);
  width: 85%;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
}


.button-approve {
    align-items: center;
    background-color: #AFD8D0;
    border: none;
    border-radius: 16px;
    box-sizing: border-box;
    color: rgb(34, 34, 34);
    cursor: pointer;
    display: flex;
    font-size: 10px;
    justify-content: center;
    line-height: 35px;
    max-width: 50%;
    padding: 18px 16px;
    text-decoration: none;
    transition: all .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
  }

  .button-deny {
    align-items: center;
    background-color: #F2C8CA;
    border: none;
    border-radius: 16px;
    box-sizing: border-box;
    color: rgb(34, 34, 34);
    cursor: pointer;
    display: flex;
    font-size: 16px;
    justify-content: center;
    line-height: 35px;
    max-width: 50%;
    padding: 18px 16px;
    text-decoration: none;
    transition: all .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
  }
  
  .button-approve:active,
  .button-approve:hover {
    outline: 0;
  }
  
  .button-normal:hover {
    background-color: #80bbaf;
    color: rgb(34, 34, 34);
    
  }
  
  .button-red:hover{
      background-color: #e0a0a3;
      color: rgb(34, 34, 34);
  }
  
  @media (min-width: 768px) {
    .button-approve {
      font-size: 16px;
      min-width: 100px;
      padding: 18px 16px;
    }
  }

  @media (min-width: 768px) {
    .button-deny {
      font-size: 16px;
      min-width: 100px;
      padding: 18px 16px;
    }
  }

  