.invalid{
    border-color: red;
}

.logo{
    padding: 1%;
    height: 100%;
    width: 50px;
    align-self: center;
    transition: width 0.3s ease;
}

.bg-shape{
    width: 520px;
    height: 520px;
    border-radius: 9999px;
    z-index: -1;
}

.bg-shape-sml{
    width: 300px;
    height: 300px;
    border-radius: 9999px;
    z-index: -1;
}

.opcaity{
    opacity: .8;
}

.bg-blur{
    filter: blur(80px);
}

.bg-blue{
    background-color: #B0D8F1;
}

.bg-green{
    background-color: #AFD8D0;
}

.bg-purple{
    background-color: #4F43B3;
}

.bg-orange{
    background-color: #F4D07A;
}

.bg-pink{
    background-color: #F2C8CA;
}

.bg-red{
    background-color: #FF7C96;
}

.top-right-corner {
    z-index: 0;
    position: absolute;
    top: -200px;
    right: -225px;
}

.bottom-right-corner {
    z-index: 0;
    position: absolute;
    top: 200px;
    right: 225px;
}



@media only screen and (max-width: 480px) {
    body {
      background-color: lightblue;
    }
  }

