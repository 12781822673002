.logo-sb{
  width: 25px;
  height: 100%;
}
.button-46 {
  align-items: center;
  background-color: #5F53C8;
  border: none;
  border-radius: 16px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 5px;
  justify-content: center;
  line-height: 10px;
  max-width: 100%;
  padding: 6px 6px;
  text-decoration: none;
  transition: all .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  box-shadow: 0 3px 2px rgba(55, 55, 55, 0.3);
}

.button-46:active,
.button-46:hover {
  outline: 0;
}

.button-normal:hover {
  background-color: #AFD8D0;
  color: #262626;
  border-color: rgba(0, 0, 0, 0.19);
}

.button-red:hover{
    background-color: #F2C8CA;
    color: #262626;
    border-color: rgba(0, 0, 0, 0.19);
}

@media (min-width: 768px) {
  .button-46 {
    font-size: 15px;
    min-width: 200px;
    padding: 14px 16px;
  }
}