.button-myGroup {
    align-items: center;
    background-color: #ecb4b7;
    border: none;
    border-radius: 16px;
    box-sizing: border-box;
    color: rgb(34, 34, 34);
    cursor: pointer;
    display: flex;
    font-size: 12px;
    justify-content: center;
    line-height: 28px;
    max-width: 100%;
    padding: 8px 8px;
    text-decoration: none;
    transition: all .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    
  }
  
  .button-myGroup:active,
  .button-myGroup:hover {
    outline: 0;
    background-color: #e59fa2;
  }
  
  .button-myGroup:hover {
    background-color: #e59fa2;
  }
  
  @media (min-width: 768px) {
    .button-myGroup {
      font-size: 18px;
      min-width: 200px;
      padding: 8px 8px;
    }
  }