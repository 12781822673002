/* .popUp{
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;
}

.popUp-inner{
    position: relative;
    padding: 10%;
    width: 100%;
    max-width: 640px;
    background-color: rgb(255, 255, 255);
} */

.invalid{
    border-color: red;
}

.button-profile {
    align-items: center;
    background-color: #5F53C8;
    border: none;
    border-radius: 16px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    justify-content: center;
    line-height: 25px;
    max-width: 50%;
    padding: 5px 5px;
    text-decoration: none;
    transition: all .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;

    
  }
  
  .button-profile:active,
  .button-profile:hover {
    outline: 0;
    background-color: #8074e7;
  }
  
  .button-profile:hover {
    background-color: #8074e7;
  }
  
  @media (min-width: 768px) {
    .button-profile {
      font-size: 18px;
      min-width: 200px;
      padding: 5px 5px;
    }
  }

.bg-backgroun{
    z-index: 1000;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: #b9bee1;
    overflow: hidden;
    opacity: .7;
}


.bg-shape{
    width: 520px;
    height: 520px;
    border-radius: 9999px;
    z-index: -1;
}

.bg-shape-sml{
    width: 300px;
    height: 300px;
    border-radius: 9999px;
    z-index: -1;
}

.opcaity{
    opacity: .8;
}

.bg-blur{
    filter: blur(80px);
}

.bg-blue{
    background-color: #B0D8F1;
}

.bg-green{
    background-color: #AFD8D0;
}

.bg-purple{
    background-color: #4F43B3;
}

.bg-orange{
    background-color: #F4D07A;
}

.bg-pink{
    background-color: #F2C8CA;
}

.bg-red{
    background-color: #FF7C96;
}

.top-right-corner {
    z-index: 0;
    position: absolute;
    top: -200px;
    right: -225px;
}

.bottom-right-corner {
    z-index: 0;
    position: absolute;
    top: 200px;
    right: 225px;
}