.grey-rounded-button {
    background-color: #666666; /* Grey background color */
    color: #ffffff; /* Text color (white) */
    border-radius: 20%; /* Rounded corners */
    padding: 3% 3% 5% 5%; /* Padding for the button content */
    cursor: pointer; /* Add a pointer cursor on hover */
    text-decoration: none;
}

.pill {
    background-color: #B0D8F1;
    border: solid;
    border-color: #9bcbe9;
    font-size: 13px;
    color: black;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: default;
    border-radius: 21px;
    margin: 1%;
}


.pill-x {
    background-color: #AFD8D0;
    border: solid;
    border-color: #9bcdc3;
    font-size: 11px;
    color: black;
    padding: 5px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    border-radius: 21px;
    margin: 1%;
}

.pill-x:hover{
    background-color: #8cc2b7;
}

