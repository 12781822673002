.pitchSection{
    border-radius: 1.5%;
    background-color: #EFF2FB;
    width: 100%;
    height: 100%;
}

.create-pitchbtn{
    border-radius: 100%;
    background-color: #5F53C8;
    border: 1px solid #3e33a4;
    padding: 10px;
   
}

.create-pitchbtn:hover{
    background-color: #8074e7
}

hr.thick{
    border: 2px solid #8a9cff8f;
    border-radius: 5px;
    width: 65%;
}

