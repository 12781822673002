.bodyColour{
    background-color: #7f8b78;
}

.title{
    font-size: 65px;
}

.logo{
    padding: 2%;
    height: 100%;
    width: 70px;
    align-self: center;
    transition: width 0.3s ease;
}

.logo:hover {
    width: 85px;
}

.blob{
    border-radius: 40% 60% 60% 40% / 70% 30% 70% 30%;
    width: 70vmin;
    height: 70vmin;
    z-index: -3;
}

.blob-m{
    border-radius: 40% 50% 70% 40% / 70% 30% 70% 30%;
    width: 55vmin;
    height: 55vmin;
    z-index: -2;
}

.blob-s{
    border-radius: 40% 50% 70% 40% / 70% 30% 70% 30%;
    width: 25vmin;
    height: 25vmin;
    z-index: -1;
}

.bg-shape{
    width: 520px;
    height: 520px;
    border-radius: 9999px;
    z-index: -1;
}

.bg-shape-sml{
    width: 300px;
    height: 300px;
    border-radius: 9999px;
    z-index: -1;
}

.opcaity{
    opacity: .1;
}

.opcaity-2{
    opacity: .2;
}

.opcaity-3{
    opacity: .3;
}

.bg-blur{
    filter: blur(45px);
}
.blur{
    filter: blur(30px);
}


.bg-blur-2{
    filter: blur(95px);
}
.blur-2{
    filter: blur(40px);
}


.sec-blur{
    filter: blur(65px);
}
.sec-blur-2{
    filter: blur(25px);
}


.bg-blue{
    background-color: #B0D8F1;
}

.bg-green{
    background-color: #AFD8D0;
}

.bg-purple{
    background-color: #4F43B3;
}

.bg-orange{
    background-color: #F4D07A;
}

.bg-pink{
    background-color: #F2C8CA;
}

.bg-red{
    background-color: #FF7C96;
}

.top-right-corner {
    z-index: 0;
    position: absolute;
    top: -190px;
    right: -225px;
}

.idk{
    z-index: 0;
    position: absolute;
    top: -50px;
    
}

.idk-1{
    z-index: 0;
    position: absolute;
    top: 5px;
    left: 70px;
}

.idk-2{
    z-index: 0;
    position: absolute;
    top: 80px;
    left: 170px;
}


.bottom-right-corner {
    z-index: 0;
    position: absolute;
    top: 200px;
    right: 225px;
}

  .container {
    overflow: hidden;
  }

  .two-blob{
    border-radius: 40% 60% 70% 50% / 80% 30% 60% 40%;
    width: 80vmin;
    height: 70vmin;
    z-index: -3;
}

.two-blob-2{
    border-radius: 40% 60% 70% 50% / 80% 30% 60% 40%;
    width: 60vmin;
    height: 60vmin;
    z-index: -2;
}

.two-blob-3{
    border-radius: 40% 60% 70% 50% / 80% 30% 60% 40%;
    width: 20vmin;
    height: 20vmin;
    z-index: -1;
}


.ps{
    z-index: 0;
    position: absolute;
    top: 10px;
    right: -150px;
}

.ps-1{
    z-index: 0;
    position: absolute;
    top: 10px;
    right: -110px;
}

.ps-2{
    z-index: 0;
    position: absolute;
    top: 158px;
    right: 50px;
}

.three-blob{
    transform: rotate(30deg);
    border-radius: 70% 60% 80% 20% / 90% 30% 60% 60%;
    width: 50vmin;
    height: 100vmin;
    z-index: -3;
}

.three-blob-2{
    transform: rotate(30deg);
    border-radius: 70% 60% 80% 20% / 90% 30% 60% 60%;
    width: 40vmin;
    height: 80vmin;
    z-index: -2;
}

.three-blob-3{
    transform: rotate(30deg);
    border-radius: 70% 60% 80% 20% / 90% 30% 60% 60%;
    width: 10vmin;
    height: 60vmin;
    z-index: -1;
}

.two-ps{
    z-index: 0;
    position: absolute;
    bottom: -500px;
    right: 770px;
}

.two-ps-2{
    z-index: 0;
    position: absolute;
    bottom: -400px;
    right: 800px;
}

.two-ps-3{
    z-index: 0;
    position: absolute;
    bottom: -340px;
    right: 900px;
}