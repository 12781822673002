
.invalid{
    border-color: red;
}

.pitchCreationComp{
    position: fixed;
    width: 40%;
    height: 70%;
    z-index: 1000;
    padding-top: 2%;
    padding-bottom: 2%;
}

.picthCard{
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    padding: 4%;
    align-self: center;
    background-color: rgb(255, 255, 255);
    border-radius: 25px;
}

.pitchInner{
    align-content: center;
}

.button-pitch {
    align-items: center;
    background-color: #5F53C8;
    border: none;
    border-radius: 16px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    font-size: 18px;
    justify-content: center;
    line-height: 25px;
    max-width: 100%;
    padding: 5px 5px;
    text-decoration: none;
    transition: all .2s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100%;
    box-shadow: 0 0 2px rgba(55, 55, 55, 0.3);
  }
  
  .button-pitch:active,
  .button-pitch:hover {
    outline: 0;
    background-color: #8074e7;
  }
  
  .button-pitch:hover {
    background-color: #8074e7;
  }
  
  @media (min-width: 768px) {
    .button-pitch {
      font-size: 18px;
      min-width: 200px;
      padding: 5px 5px;
    }
  }

  .tag:hover{
    background-color: #899dff94;
  }